import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./Checkout.css";
import { FaLock } from "react-icons/fa";
import membershipImg from "../../images/coroana.png";
import axios from "axios";
import config from "../../config";
import { useTranslation } from "react-i18next";

function Checkout() {
    const navigate = useNavigate();
    const allProductsTypes = ["combo_credits", "credits", "memberships"];
    // get from query params receiver_id

    const [user, setUser] = useState();
    const [currentProduct, setCurrentProduct] = useState([]);
    const [extraCredits, setExtraCredits] = useState(0);
    const { product_type, product_id } = useParams();
    const { t, i18n, ready } = useTranslation();

    const getProducts = async () => {
        if (localStorage.getItem("token")) {
            const response = await axios.get(config.url + "products", {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            return response.data;
        }
    };

    useEffect(() => {
        if (!allProductsTypes.includes(product_type)) {
            navigate("/membership-credits");
        }
        const first_login_date = localStorage.getItem("first_login_date");
        const seconds = Math.floor(
            (new Date() - new Date(first_login_date)) / 1000
        );

        if (seconds < 3600 && product_type === "memberships") {
            setExtraCredits(500);
        }
    }, []);
    useEffect(() => {
        getProducts()
            .then((products) => {
                if (products.length === 0) return;
                if (products[product_type] == undefined) return;

                const product = products[product_type].find((obj) => {
                    return obj.id == product_id;
                });

                if (product === undefined) {
                    navigate("/membership-credits");
                }
                setCurrentProduct(product);
                console.log(product);
            })
            .catch((err) => {
                if (err) {
                    navigate("/membership-credits");
                }
            });
    }, []);

    function paymentRequest() {
        const sendPaymentRequest = async () => {
            let data = {};
            if (product_type === "memberships") {
                data["membership_id"] = currentProduct.id;
                if (user) {
                    data["receiver_id"] = user.id;
                }
            } else if (
                product_type === "combo_credits" ||
                product_type === "credits"
            ) {
                data["credit_id"] = currentProduct.id;
                if (user) {
                    data["receiver_id"] = user.id;
                }
            }

            return await axios.post(config.url + "payments/request", data, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
        };

        sendPaymentRequest()
            .then((result) => {
                if (
                    result.status === 200 &&
                    result.data.data.payment_form_url
                ) {
                    window.location.replace(result.data.data.payment_form_url);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const receiver_id = urlParams.get("receiver_id");
        const getProfileDetails = async () => {
            if (receiver_id === undefined) return;
            const response = await axios.get(
                config.url + "users/id/" + receiver_id,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            );
            console.log(response.data.user);
            setUser(response.data.user);
        };

        getProfileDetails();
    }, []);

    return (
        <div className="container checkout-container">
            <div className="checkout-details">
                <div className="checkout-header">
                    {" "}
                    <h2>Checkout</h2>{" "}
                </div>
                <div className="line-subtitle">
                    <h5>{t("Product Details")}</h5>
                </div>
                <div className="product-detail">
                    <div className="pricing-image-mobile">
                        {" "}
                        <img src={currentProduct?.picture} />
                    </div>
                </div>
                <div className="product-detail">
                    <div className="pricing-image">
                        {" "}
                        <img src={currentProduct?.picture} />
                    </div>
                    <div>
                        {" "}
                        <h4>{currentProduct?.name}</h4>{" "}
                        <span>
                            {" "}
                            {currentProduct?.available_for_days
                                ? currentProduct?.available_for_days +
                                  t(" days")
                                : ""}{" "}
                            {extraCredits > 1 && (
                                <span className="prom-off">
                                    {"+ " + extraCredits + " " + t("credits")}
                                </span>
                            )}
                        </span>
                    </div>
                    <div className="div-price">
                        <span
                            style={{
                                height: "20px",
                                textDecoration: "line-through",
                            }}
                        >
                            {product_type === "memberships" && (
                                <>
                                    {currentProduct.old_price}€
                                    <span className="prom-off">
                                        {currentProduct.promotion_percentage}%
                                        OFF
                                    </span>
                                </>
                            )}
                        </span>
                        <span className="t-color bld card-price">
                            {currentProduct?.price
                                ? currentProduct?.price + " €"
                                : ""}
                        </span>{" "}
                    </div>
                </div>
                <div className="product-benefits">
                    {currentProduct?.credits && (
                        <span className="each-benefit vb-color">
                            {currentProduct?.credits
                                ? currentProduct.credits + " credits"
                                : ""}
                        </span>
                    )}
                    {currentProduct?.bonus ? (
                        <span className="each-benefit vb-color">
                            {currentProduct?.bonus
                                ? currentProduct.bonus + " credits BONUS"
                                : ""}
                        </span>
                    ) : null}
                    {currentProduct?.benefits &&
                        currentProduct.benefits.map((benefit) => {
                            if (benefit.value > 0)
                                return (
                                    <span className="each-benefit vb-color">
                                        {benefit.value_text
                                            ? benefit.value_text
                                            : benefit.name}
                                    </span>
                                );
                        })}
                </div>
                <div className="line-subtitle">
                    <h5>{t("Payment Method")}</h5>
                </div>
                <div className="payment-method">
                    <div className="payment-method-item">
                        <div className="payment-method-name">
                            <span>{t("Online Card")}</span>
                        </div>
                        <div className="payment-method-image">
                            <FaLock />
                        </div>
                    </div>
                </div>

                <div className="checkout-provider">
                    {" "}
                    <button
                        className="main-btn tb-color f-white howver"
                        onClick={paymentRequest}
                    >
                        Go to payment provider page
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Checkout;
